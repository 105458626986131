
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AcelMGMService } from 'lms-api';
import { ModalComponent } from '../modal/modal.component';
import { ModalCreateCardService } from './modal-create-card.service';

@Component({
  selector: 'app-modal-create-card',
  templateUrl: './modal-create-card.component.html',
  styleUrls: ['./modal-create-card.component.scss']
})
export class ModalCreateCardComponent implements OnInit {

  cf:string;
  mgm:string;
  done = false;
  formCodiceFiscale: FormGroup;
  

  constructor(public dialogRef: MatDialogRef<ModalCreateCardComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private fb: FormBuilder,
    private modalService: ModalCreateCardService,
    private acelMGMService: AcelMGMService, public matDialog: MatDialog) { }

  ngOnInit(): void {
    this.buildForm();
  }

  public buildForm(): void {
    this.formCodiceFiscale = this.fb.group({
      fiscalcode: ['', [Validators.required, Validators.pattern('^([A-Za-z]{6}[0-9lmnpqrstuvLMNPQRSTUV]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9lmnpqrstuvLMNPQRSTUV]{2}[A-Za-z]{1}[0-9lmnpqrstuvLMNPQRSTUV]{3}[A-Za-z]{1})$')]]
    });
  }

  actionFunction() {
    
    this.modalService.modalAction(this.modalData);
    this.closeModal();
  }

  closeModal() {
    this.dialogRef.close();
  }
  

  createCard(){
    this.formCodiceFiscale.markAllAsTouched();
    if (this.formCodiceFiscale.invalid) {
      return;
    }
    
    this.dialogRef.close();
    this.acelMGMService.acelMGMGenerateMGMCode(this.formCodiceFiscale.value.fiscalcode).subscribe(ret => {

      this.mgm = ret;
      this.done = true;
      this.openModalCFAdded();

    });
  }

  openModalCFAdded() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.panelClass = 'myapp-no-padding-dialog';
    // dialogConfig.height = '350px';
    dialogConfig.width = '600px';
    dialogConfig.data = {
      title: 'OPERAZIONE EFFETTUATA',
      actionButtonText: 'OK',
      message: 'Codice Amico creato correttamente, puoi trovare la posizione del cliente dalla maschera di ricerca'
     // newRoute: ''
    };
    const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
  }

}
