import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalResetPasswordActionsService } from './modal-reset-password-actions.service';


@Component({
  selector: 'app-modal',
  templateUrl: './modal-reset-password.component.html',
  styleUrls: ['./modal-reset-password.component.scss']
})
export class ModalResetPasswordComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ModalResetPasswordComponent>,
              @Inject(MAT_DIALOG_DATA) public modalResetPasswordData: any,
              private modalService: ModalResetPasswordActionsService) {
  }

  ngOnInit() {
  }

  actionFunction() {
    this.modalService.modalAction(this.modalResetPasswordData);
    this.closeModal();
  }

  closeModal() {
    this.dialogRef.close();
  }

}
