import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ModalUtenteEsistenteComponent } from '../components/modal-utente-esistente/modal-utente-esistente.component';
import { ModalComponent } from '../components/modal/modal.component';

@Injectable({ providedIn: 'root' })
export class AuthService {

  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private hasClaimsSubjct$ = new BehaviorSubject<boolean>(false);
  public hasClaims$ = this.hasClaimsSubjct$.asObservable();


  private claimsSubjct$ = new BehaviorSubject<object>(null);
  public claims$ = this.claimsSubjct$.asObservable();

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  claims: any = {};

  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$
  ]).pipe(map(values => values.every(b => b)));

  private navigateToLoginPage() {
    // TODO: Remember current URL
    this.router.navigateByUrl('/login');
  }

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    public matDialog: MatDialog
  ) {
    // Useful for debugging:
    /* this.oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        console.error('OAuthErrorEvent Object:', event);
      } else {
        console.warn('OAuthEvent Object:', event);
      }
    }); */

    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    // TODO: Improve this setup. See: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/issues/2
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

      if (!this.oauthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      }
    });

    this.oauthService.events
      .subscribe(_ => {
        this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
        this.hasClaimsSubjct$.next(this.oauthService.getIdentityClaims() !== null);
        if (this.oauthService.getIdentityClaims() !== null) {
          this.claimsSubjct$.next(this.oauthService.getIdentityClaims());
        }
      });

    this.oauthService.events
      .pipe(filter(e => ['token_received'].includes(e.type)))
      .subscribe(e => this.oauthService.loadUserProfile());

    this.oauthService.events
      .pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type)))
      .subscribe(e => this.navigateToLoginPage());

    this.oauthService.events
      .pipe(filter(e => ['code_error'].includes(e.type)))
      .subscribe(e => this.navigateToLoginPage());

     // this.oauthService.setupAutomaticSilentRefresh();
  }


  public runInitialLoginSequence(): Promise<void> {
    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:
    return this.oauthService.loadDiscoveryDocument()

      // For demo purposes, we pretend the previous call was very slow
    //  .then(() => new Promise(resolve => setTimeout(() => resolve(), 1000)))

      // 1. HASH LOGIN:
      // Try to log in via hash fragment after redirect back
      // from IdServer from initImplicitFlow:
      .then(() => this.oauthService.tryLogin())

      .then(() => {
        if (this.oauthService.hasValidAccessToken()) {
          return Promise.resolve();
        }

        // Da eliminare i commenti non appena ci sara la silent login
        // 2. SILENT LOGIN:
        // Try to log in via a refresh because then we can prevent
        // needing to redirect the user:
       /* return this.oauthService.silentRefresh()  // Da eliminare i commenti non appena ci sara la silent login
          .then(() => Promise.resolve())
          .catch(result => {
            // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
            // Only the ones where it's reasonably sure that sending the
            // user to the IdServer will help.
            const errorResponsesRequiringUserInteraction = [
              'interaction_required',
              'login_required',
              'account_selection_required',
              'consent_required',
            ];

            if (result
              && result.reason
              && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {

              // 3. ASK FOR LOGIN:
              // At this point we know for sure that we have to ask the
              // user to log in, so we redirect them to the IdServer to
              // enter credentials.
              //
              // Enable this to ALWAYS force a user to login.
              // this.login();
              //
              // Instead, we'll now do this:
              console.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
              return Promise.resolve();
            }

            // We can't handle the truth, just pass on the problem to the
            // next handler.
            return Promise.reject(result);
          }); */ // Da eliminare i commenti non appena ci sara la silent login
      })

      .then(() => {
        this.isDoneLoadingSubject$.next(true);
        // Check for the strings 'undefined' and 'null' just to be sure. Our current
        // login(...) should never have this, but in case someone ever calls
        // initImplicitFlow(undefined | null) this could happen.
        if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
          let stateUrl = this.oauthService.state;
          if (stateUrl.startsWith('/') === false) {
            stateUrl = decodeURIComponent(stateUrl);
          }
          this.router.navigateByUrl(stateUrl);
        }
      })
      .catch(() => this.isDoneLoadingSubject$.next(true));
  }

  public loginFacebook() {
    this.oauthService.initLoginFlow('/signupFB');
  }

  public login(targetUrl?: string) {
    this.oauthService.initLoginFlow(targetUrl || this.router.url);
  }


  public loginUser(username: string, password: string) {
    const url = environment.issuer + '/.well-known/openid-configuration';
    this.oauthService.loadDiscoveryDocument(url).then(() => {
    this.oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(username, password).then(user => {
      // console.log(user);
      /* if(user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] === 'operatore') {
        this.router.navigateByUrl('/area-operatore');
        
      } */

      if(!user['id'].includes('@')) {
        this.router.navigateByUrl('/area-operatore');
        
      }
      else {
        
        this.router.navigateByUrl('/dashboard');
      }
      
      
      })
      .catch ((error) => {
        //console.log(error);
        this.openModalError();
      });
    });

  }

  openModalErrorLogin(error) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.panelClass = 'myapp-no-padding-dialog';
    // dialogConfig.height = '350px';
    dialogConfig.width = '600px';
    console.log(error);
    let errorMessage = '';
    if(error.error.error_description == 'email_not_confirmed') {
      errorMessage = 'Gentile utente la tua registrazione risulta incompleta perché non hai mai confermato il tuo indirizzo email, a breve riceverai una nuova email di conferma, cliccando sul link della mail potrai confermare il tuo indirizzo e accedere nuovamente alla piattaforma Energy Cup. Ti ricordiamo che avere una mail valida è un requisito indispensabile per far parte del mondo Energy Cup';
    }
    else {
      errorMessage = 'email o password non riconosciute, controlla di averle digitate correttamente o procedi con il recupero';
    }
    dialogConfig.data = {
      action: 'reload',
      title: 'Attenzione',
      message: errorMessage,
      actionButtonText: 'OK',
      newRoute: ''
    };
    const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
  }

  openModalError() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'modal-component';
    dialogConfig.panelClass = 'myapp-no-padding-dialog';
    // dialogConfig.height = '350px';
    dialogConfig.width = '600px';
     
    dialogConfig.data = {
      action: 'goToNewRoute',
      title: 'Attenzione',
      message1: 'Attenzione, credenziali non corrette, se hai dimenticato la password recuperela cliccando al seguente',
      // actionButtonText: 'OK',
      newRoute: 'reset-password'
    };
    const modalDialog = this.matDialog.open(ModalUtenteEsistenteComponent, dialogConfig);
  }


  setSession(claims: any) {
    this.claims = claims;
    sessionStorage.setItem('userId', claims.sub);
    sessionStorage.setItem('fiscal_code', claims['fiscal_code']);
    sessionStorage.setItem('username', claims['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier']);
    sessionStorage.setItem('email', claims['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress']);
    sessionStorage.setItem('name', claims['name']);
    sessionStorage.setItem('role', claims['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']);
    
  }

  public logout() { this.oauthService.logOut(); sessionStorage.clear();  }
  public refresh() { this.oauthService.silentRefresh(); }
  public hasValidToken() { return this.oauthService.hasValidAccessToken(); }
  public getClaims() { return this.oauthService.getIdentityClaims(); }

}
