import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ModalActionsService {

  constructor(private router: Router,
              @Inject(DOCUMENT) private document: Document) {
  }

  modalAction(modalData: any) {
    switch (modalData.action) {
      case 'goToNewRoute':
        this.goToNewRoute(modalData);
        break;
      case 'reload':
          this.document.defaultView.location.reload();
          break;
      case 'entraAcel0':
        this.navigateExt(0);
        break;
      case 'entraAcel1':
        this.navigateExt(1);
        break;
      case 'whatsapp':
        // console.log('whatsapp');
        this.navigateExt(2);
        break;
      case 'call':
        // console.log('whatsapp');
        this.navigateExt(3);
        break;
      case 'nothing':
        this.document.close();
      default:
        break;
    }
  }

  private goToNewRoute(modalData: any) {
    this.router.navigateByUrl(modalData.newRoute);
  }

  private navigateExt(caso){
    
    if(caso == 0)
      window.open('https://www.acelenergie.it/contatti/store-locator.html', '_blank');
    if(caso == 1)
      window.open('https://www.acelenergie.it/offerte-casa/mia-dinamica-luce.html', '_blank');
    if(caso == 2)
      window.open('https://wa.me/393489891003?text=Ciao,%20ho%20ricevuto%20un%20invito%20con%20Codice%20Amico%20per%20sottoscrivere%20un%20contratto%20con%20voi,%20mi%20potete%20aiutare?', '_blank');
    if(caso == 3)
      window.open('tel:800.822.034 ','_parent', 'call');
  } 

  
}
