 <!--come funziona-->
 <div class="faq-div">
    <div class="container-2 w-container text-center">
        <h1 class="heading-2 mt-50 " id="comeFunziona">COME FUNZIONA</h1>
        <img src="../../assets/images/come-funziona.png" alt="amici premiati come funziona" class="come-funziona"/>
        <img src="../../assets/images/come-funziona-mb.png" alt="amici premiati come funziona" class="come-funziona-mb" />



        <button *ngIf="collapsed" (click)="expand()" mat-stroked-button  class="button-border btn-text text-white" >
            SCOPRI DI PIù - FAQ
        </button>
        <button *ngIf="!collapsed" (click)="collapse()" mat-stroked-button  class="button-border btn-text text-white" >
            CHIUDI
        </button>
        <section class="collapsible" [@collapse]="collapsed">
        <mat-accordion>
            <mat-expansion-panel  hideToggle>
            <mat-expansion-panel-header class="header">
                <mat-panel-description class="question" >
                    Chi può partecipare all’iniziativa Amici Premiati?
                </mat-panel-description>
            </mat-expansion-panel-header>
            <p class="answer">
              In qualità di PRESENTATORE i clienti di Acinque Energia
              (ex Acel, Enerxenia o Aevv) che abbiano attivato una o più
              offerte luce e/o gas ad uso domestico, con almeno un contratto
              di fornitura in attivazione o attivo, in regola con i pagamenti.
              In qualità di PRESENTATO i soggetti che su invito di un Presentatore,
              sottoscrivono un’offerta di fornitura di gas e/o energia elettrica a
              mercato libero ad uso domestico con Acinque Energia (ex Acel, Enerxenia o Aevv)
              nel periodo di validità dell’iniziativa. Sono escluse per la partecipazione
              all’iniziativa per gli amici, le offerte specificate nelle modalità
              di partecipazione.
              <a href = "../../assets/pdf/Regolamento_Amici_Premiati_23.pdf" target = "_blank">Link</a>
            </mat-expansion-panel>
            <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header class="header">
                    <mat-panel-description class="question" >
                        In quale periodo è attiva l’iniziativa?
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p class="answer">
                  La prima edizione dell'iniziativa Amici Premiati è stata attiva
                  dal 10/05/2022 al 31/03/2023 (data di sottoscrizione dei nuovi contratti).
                  La seconda edizione è attiva dal 01/04/2023 al 31/03/2024
                  (data di sottoscrizione dei nuovi contratti).
                  Acinque Energia si riserva la facoltà di estendere
                  la validità di questa iniziativa dandone congruo preavviso.
                </p>

            </mat-expansion-panel>
            <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header class="header">
                    <mat-panel-description class="question" >
                        Come funziona Amici Premiati?
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p class="answer">
                  Se sei un PRESENTATORE, puoi richiedere il tuo codice amico e
                  inviarlo ai tuoi amici. Quando attiveranno una fornitura a
                  mercato libero utilizzando il codice che gli hai condiviso,
                  sia tu che loro riceverete uno sconto in bolletta del valore di 30 euro.
                </p>
            </mat-expansion-panel>
            <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header class="header">
                    <mat-panel-description class="question" >
                        Come posso invitare un amico? Dove trovo il mio codice amico?
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p class="answer">
                  Per invitare un amico è sufficiente che tu condivida con lui il tuo Codice Amico. L
                  o puoi trovare accedendo all'area riservata del sito promozionale www.amicipremiati.it;
                  nelle comunicazioni email e/o cartacee che ti abbiamo inviato; oppure puoi rivolgerti
                  al più vicino Acinque Point, ne abbiamo ben 22 nel tuo territorio!
                  Il Codice Amico viene generato anche se la tua prima fornitura
                  con Acinque Energia (ex Acel, Enerxenia o Aevv) risulta ancora in fase di attivazione:
                  i vantaggi eventuali li otterrai solo nel momento in cui la tua offerta passerà in
                  stato Attivo.
                </p>
                </mat-expansion-panel>
            <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header class="header">
                    <mat-panel-description class="question" >
                        Perché devo registrarmi ad Amicipremiati.it?
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p class="answer">
                  La registrazione alla piattaforma amicipremiati.it ti permette di entrare in possesso del
                  tuo Codice Amico e ti mette a disposizione una serie di funzionalità utili come ad esempio:
                    <br>- strumenti per la condivisione del tuo Codice Amico con i tuoi amici e conoscenti
                    <br>- valorizzazione degli sconti accumulati
                    <br>- possibilità di selezionare la fornitura di accredito degli sconti.
<br>
                  La registrazione alla piattaforma non è un passaggio necessario
                  per la partecipazione all'iniziativa, ma te lo consigliamo per
                  poter tenere tutto sotto controllo e avere accesso a questi strumenti.
                </p>
            </mat-expansion-panel>
            <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header class="header">
                    <mat-panel-description class="question" >
                        Sono titolare di  una fornitura Gas a mercato tutelato, posso partecipare all'iniziativa?
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p class="answer">
                  Sì, come indicato nelle modalità di partecipazione, puoi partecipare in qualità di Presentatore ma potrai vedere accreditato il premio previsto solo su una fornitura a mercato libero, quindi se non hai altre forniture già attive, solo nel momento in cui passerai la tua fornitura al mercato libero potrai ricevere i tuoi sconti.
                </p>
            </mat-expansion-panel>
            <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header class="header">
                    <mat-panel-description class="question" >
                        Sono un cliente Presentatore, posso utilizzare il mio codice amico per sottoscrivere una nuova offerta?
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p class="answer">
                  No, il tuo Codice Amico è valido solo se utilizzato da altri soggetti; anche tu puoi attivare una nuova fornitura godendo dei vantaggi di questa iniziativa, è sufficiente che utilizzi il codice Amico di un altro soggetto.
                </p>
            </mat-expansion-panel>
            <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header class="header">
                    <mat-panel-description class="question" >
                        Ho ricevuto un Codice Amico, come posso fare per ottenere lo sconto?
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p class="answer">
                  Recati presso uno dei punti vendita Acinque Energia (ex Acel, Enerxenia o Aevv) oppure chiama l'800.822.034 e procedi alla sottoscrizione di un nuovo contratto di fornitura Gas Naturale o Energia Elettrica, comunicando di essere in possesso di un Codice Amico.

                  In caso di sottoscrizione autonoma online tramite uno dei siti aziendali, il Codice Amico andrà inserito nell'apposito campo Codice Promozionale.
                </p>
            </mat-expansion-panel>
            <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header class="header">
                    <mat-panel-description class="question" >
                        Quale vantaggio posso ottenere partecipando a questa iniziativa?
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p class="answer">
                  Se sei un cliente Presentatore, puoi ottenere 30€ di sconto in bolletta ogni volta che il tuo Codice Amico viene utilizzato in fase di sottoscrizione. Se sei un nuovo cliente e utilizzi il Codice Amico di un Presentatore al momento della tua sottoscrizione del contratto ottieni 30€ di sconto sulla prima bolletta. Il singolo soggetto può accumulare fino a 360€ di sconto per ciascuna edizione. Dettagli e condizioni complete sulle modalità di partecipazione.
                </p>
            </mat-expansion-panel>
            <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header class="header">
                    <mat-panel-description class="question" >
                        Quando riceverò lo sconto in bolletta?
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p class="answer">
                  Se sei un cliente Presentatore, maturerai il diritto a ricevere lo sconto in bolletta nel momento in cui la nuova fornitura attivata con il tuo codice Amico diviene Attiva. Lo sconto sarà accreditato nella prima bolletta utile a partire dal mese successivo all'attivazione della fornitura da parte dell'Amico. Se lo sconto è legato a un tuo nuovo contratto, già nella prima bolletta potrai trovare lo sconto. Qualora l'importo del tuo credito superasse l'importo della bolletta, il residuo verrà accreditato nelle successive bollette fino ad esaurimento del credito.
                </p>
            </mat-expansion-panel>
            <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header class="header">
                    <mat-panel-description class="question" >
                        Su quale fornitura mi verrà accreditato lo sconto?
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p class="answer">
                  Se sei un cliente Presentatore, lo sconto ti sarà accreditato sulla tua fornitura Gas Metano più longeva (con data di attivazione precedente). Nel caso in cui non disponessi di una fornitura Gas, ti sarà accreditato sulla fornitura Luce più longeva. Nel caso in cui volessi modificare queste priorità, potrai intervenire accedendo all'area riservata di www.amicipremiati.it e selezionando direttamente la fornitura su cui accreditare lo sconto. Attenzione, questa selezione varrà per le scontistiche non ancora accreditate o trasmesse ad Acinque per l'accredito. Se sei un cliente presentato, lo sconto verrà accreditato sulla fornitura attivata con il Codice Amico.
                </p>
            </mat-expansion-panel>
            <!--<mat-expansion-panel hideToggle>
                <mat-expansion-panel-header class="header">
                    <mat-panel-description class="question" >
                        domanda
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <p class="answer">risposta
            </mat-expansion-panel>-->

        </mat-accordion>

    </section>
    </div>
</div>
