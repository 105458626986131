<div class="faq-div">
  <div class="mainsection footer wf-section">

  </div>
  <div class="legalstrip">
    <div class="footercontainer">
      <div class="text-block-2">Iniziativa promozionale “Amici Premiati” valida dal 01/04/2023 al 31/03/2024.
        Iniziativa riservata ai Clienti Domestici a mercato libero (persone fisiche, residenti in Italia)
        Acinque Energia (ex Acel, Enerxenia o Aevv) che condivideranno il proprio Codice Amico con uno o più conoscenti,
        invitandoli a sottoscrivere un contratto di luce e/o gas a mercato libero rientranti nelle tipologie di offerte
        dettagliate nel regolamento e con le modalità ed esclusioni ivi previste.

        Iniziativa promozionale “Amici Premiati” valida prima edizione dal 10/05/2022 al 31/03/2023 e seconda edizione
        dal 01/04/2023 al 31/03/2024. Iniziativa riservata ai Clienti Domestici a mercato libero (persone fisiche,
        residenti in Italia)
        Acinque Energia (ex Acel, Enerxenia o Aevv) che condivideranno il proprio Codice Amico con uno o più conoscenti,
        invitandoli a
        sottoscrivere un contratto di luce e/o gas a mercato libero rientranti nelle tipologie di offerte dettagliate
        nel
        regolamento e con le modalità ed esclusioni ivi previste.
        Il premio massimo accumulabile per ogni partecipante è di 360€ per ciascuna edizione.



        <br><br>Per informazioni e assistenza chiama il servizio clienti Acel al numero
        <a [routerLink]="[]" (click)="call()" style="color: white; text-decoration: underline;">800.822.034</a>
        (dal lunedì al venerdì dalle 8.30 alle 19.30, sabato dalle 9.00 alle 13).



        <br> <br>Acinque Energia S.r.L. Sede legale:
        via Amendola, 4 - 23900 Lecco (LC) R.I. C.C.I.A.A. di Lecco. C.F. e P. IVA 03773040138<br><br>
        <!-- a href = "assets/pdf/Regolamento.pdf" target="_blank" style = "color: white; text-decoration: underline; ">Regolamento prima edizione</a>
        <a href = "assets/pdf/Regolamento_Amici_Premiati_23.pdf" target="_blank" style = "color: white; margin-left:10px; text-decoration: underline; ">Regolamento seconda edizione</a-->
        <a href="assets/pdf/Regolamento.pdf" target="_blank"
          style="color: white; text-decoration: underline; ">Regolamento</a>
        <a href="../../../cookie.html" target="_blank"
          style="color: white; margin-left:10px; text-decoration: underline;">Cookie Policy</a>
        <a href="../../../privacy.html" target="_blank"
          style="color: white; margin-left:10px; text-decoration: underline;">Privacy Policy</a>
      </div>
    </div>
  </div>
</div>