import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { ApiModule, BASE_PATH } from 'lms-api';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { FallbackComponent } from './fallback.component';
import { NotFoundComponent } from './notfound.component';
import { ShouldLoginComponent } from './should-login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { ErrorDialogComponent } from './error-dialog/errordialog.component';
import { ModalResetPasswordComponent } from '../app/components/modal-reset-password/modal-reset-password.component';
import { ModalResetPasswordActionsService } from '../app/components/modal-reset-password/modal-reset-password-actions.service';
import { ErrorDialogService } from './error-dialog/errordialog.service';
import { MyMaterialModule } from './material.module';
import { FooterComponent } from './components/footer/footer.component';
import {NgTinyUrlModule} from 'ng-tiny-url';
import { FaqComponent } from './components/faq/faq.component';
import { ModalUtenteEsistenteComponent } from './components/modal-utente-esistente/modal-utente-esistente.component';
import { ModalCreateCardComponent } from './components/modal-create-card/modal-create-card.component';


@NgModule({
  declarations: [
    AppComponent,
    FallbackComponent,
    NotFoundComponent,
    ShouldLoginComponent,
    ErrorDialogComponent,
    ModalResetPasswordComponent,
    FooterComponent,
    FaqComponent,
    ModalUtenteEsistenteComponent,
    ModalCreateCardComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule.forRoot(),
    AppRoutingModule,
    ApiModule,
    HttpClientModule,
    MyMaterialModule,
    NgTinyUrlModule
  ],
  providers: [
    ApiModule,
    ErrorDialogService,
    ModalResetPasswordActionsService,
    { provide: BASE_PATH, useValue: environment.lmsApiBasePath },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


