import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  prod = false;

  constructor() {
    this.prod = environment.production;
  }

  ngOnInit() {
  }

  call(){
    window.open('tel:800.822.034 ','_parent', 'call'); 
  }
  
}
