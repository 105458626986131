import { Component } from '@angular/core';

@Component({
  selector: 'app-notfound',
  template: `<div class="text-center">
    Pagina non trovata torna alla <a href="dashboard"><b>dashboard</b></a>
  </div>`,
})
export class NotFoundComponent {
}
