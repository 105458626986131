import { Component } from '@angular/core';

@Component({
  selector: 'app-fallback',
  template: `<div class="text-center">
  Pagina non trovata torna alla <a href="/dashboard"><b>dashboard </b></a> <a href="/home"><b>home</b></a>
</div>`,
})
export class FallbackComponent {
}
