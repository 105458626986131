<div id="modal-content-wrapper">
  
    <header id="modal-header">
      <button class="close" mat-button (click)="closeModal()">X</button>
        <h4 class="numberstitle" id="modal-title">{{ modalData.title }} 
        <br/> {{ modalData.present }}</h4>
    </header>
    <div class="filocolorato"></div>
    <section id="modal-body">
      <div class="text-block text-blu text-center">
        {{ modalData.message1 }}<a (click)="actionFunction()" class="text-center" style="cursor: pointer;text-decoration: underline;"> link </a>.
      </div>
      
    </section>
    <footer id="modal-footer">
     <!-- <a class="cancel-button" (click)="closeModal()">
        {{ modalData.cancelButtonText }}
      </a>-->
      <a *ngIf="modalData.actionButtonText" (click)="actionFunction()">
        {{ modalData.actionButtonText }}
      </a>
      
      
    </footer>
  </div>
  