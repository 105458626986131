<div id="modal-content-wrapper">

    <header id="modal-header">
      <button class="close" mat-button (click)="closeModal()">X</button>
        <h4 *ngIf = "!done" class="numberstitle" id="modal-title">{{ modalData.title }} 
        <br/> {{ modalData.present }}</h4>
        <h4 *ngIf = "done" class="numberstitle" id="modal-title">
          Complimenti</h4>
    </header>
    
    <div class="filocolorato"></div>
    <section id="modal-body">
      
      <form  style = "margin-top: 30px;" [formGroup]="formCodiceFiscale">
        <mat-form-field class="full-width mb-10">
          <mat-label>Codice Fiscale</mat-label>
          <input  matInput  placeholder="Codice Fiscale" formControlName="fiscalcode" required>
          <mat-error>Inserire un codice fiscale valido</mat-error>
      </mat-form-field>
        
        <a *ngIf="done" (click)="closeModal()">
          Codice Amico creato correttamente, puoi trovare la posizione del cliente dalla maschera di ricerca <br>
          Ecco il Codice Amico creato: {{mgm}}
        </a>
        
     </form>
    </section>
    <footer id="modal-footer">
     <!-- <a class="cancel-button" (click)="closeModal()">
        {{ modalData.cancelButtonText }}
      </a>-->
      <button  *ngIf="modalData.actionButtonText && !done" mat-stroked-button class="button-amici btn-text " style="margin:0px" [disabled]="formCodiceFiscale.invalid" (click)="createCard()">
        {{ modalData.actionButtonText }}
        </button>  
     
      <a *ngIf="done" (click)="closeModal()">
        Ok
      </a>
      
      
    </footer>
  </div>
  