import { Component, OnInit } from '@angular/core';
import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
const DEFAULT_DURATION = 300;

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  animations: [
    trigger('collapse', [
      state('false', style({ height: AUTO_STYLE, display: 'block' })),
      state('true', style({ height: '0', display: 'none' })),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
    ])
  ]
})
export class FaqComponent implements OnInit {
  isShown: boolean = false ; // hidden by default
  collapsed = true;

  
  constructor() { }

  ngOnInit(): void {
  }

  toggleShow() {
  
    this.isShown = ! this.isShown;
    
  }


  toggle() {
    this.collapsed = !this.collapsed;
  }

  expand() {
    this.collapsed = false;
  }

  collapse() {
    this.collapsed = true;
  }

}
