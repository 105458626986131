
  <div id="modal-content-wrapper">
    <header id="modal-header">
        <h4 class="numberstitle" id="modal-title">Attenzione</h4>
    </header>
    <section id="modal-body">
        <div class="text-block text-blu text-center">{{data.message}}</div>
    </section>
    <footer id="modal-footer">
      <a class="cancel-button"  (click)="closeModal()">
        OK
       </a>     
      
    </footer>
  </div>