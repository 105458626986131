import { Injectable } from '@angular/core';
import { ErrorDialogService } from '../error-dialog/errordialog.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ModalComponent } from '../components/modal/modal.component';
import { ModalUtenteEsistenteComponent } from '../components/modal-utente-esistente/modal-utente-esistente.component';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(public errorDialogService: ErrorDialogService, public matDialog: MatDialog,) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log('interceptor ' + sessionStorage.getItem('access_token'));
        // if (!environment.production) {
        const token: string = sessionStorage.getItem('access_token');
        /* per ora non serve da togliere il commento */
        if (token) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }

        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
        // }
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // console.log('sono qui errore');
                    // console.log('event--->>>', event);
                    // this.errorDialogService.openDialog(event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                let data = {};
                data = {
                    status: error.error && error.error.errors && error.error.errors[0] ? error.error.errors[0].status : '',
                    message : error.error && error.error.errors && error.error.errors[0] ?  error.error.errors[0].detail : ''
                };
                // console.log(error.error.errors[0].title);
                if (error.error && error.error.errors && error.error.errors[0] && error.error.errors[0].title === 'Username already exists') {
                    // console.log('sono qui');
                    const dialogConfig = new MatDialogConfig();
                    dialogConfig.disableClose = true;
                    dialogConfig.id = 'modal-component';
                    dialogConfig.panelClass = 'myapp-no-padding-dialog';
                    // dialogConfig.height = '350px';
                    dialogConfig.width = '600px';
                    dialogConfig.data = {
                      action: 'goToNewRoute',
                      title: 'Attenzione',
                      message1: error.error.errors[0].detail,
                      // actionButtonText: 'OK',
                      newRoute: 'reset-password'
                      
                    };
                    const modalDialog = this.matDialog.open(ModalUtenteEsistenteComponent, dialogConfig);
                  
                }

                else if(error.statusText === "Bad Request" && sessionStorage.getItem('role') === 'operatore'){
                    if(error.error === 'Fiscal Code already exists') {
                        this.openModalError('Cliente già inserito, puoi trovare la posizione del cliente dalla maschera di ricerca');
                    }
                    else {
                        this.openModalError('Codice Fiscale o Codice Amico non presente nei nostri sistemi');
                    }
                      
                    
                }

                else if(error.error === 'Instant Win Stock not found' ) {
                    this.openModalSorry();
                }
                else if(error.error && error.error.errors && error.error.errors[0] && error.error.errors[0].title === 'Invalid Token') {
                    
                    data = { message:'Gentile utente il link è scaduto, ti abbiamo inviato una mail di conferma più recente'};
                    this.errorDialogService.openDialog(data);
                   
                }
                else if (error.error && error.error.error_description !== 'invalid_username_or_password'
                            && error.error && error.error.error_description !== 'email_not_confirmed')
                {
                    this.errorDialogService.openDialog(data);
                }
                
                
                
                
                return throwError(error);
            })
        );
    }

    openModalSorry() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.id = 'modal-component';
        // dialogConfig.height = '350px';
        dialogConfig.width = '600px';
        dialogConfig.panelClass = 'myapp-no-padding-dialog';
        dialogConfig.data = {
          action: 'goToNewRoute',
          title: 'Non hai vinto...',
          message: 'Guadagna altre Energy Ball e ritenta la fortuna!',
          actionButtonText: 'Torna indietro',
          newRoute: '/dashboard'
        };
        const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
      }
      openModalError(error) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.id = 'modal-component';
        dialogConfig.panelClass = 'myapp-no-padding-dialog';
        // dialogConfig.height = '350px';
        dialogConfig.width = '600px';
        dialogConfig.data = {
          action: 'nothing',
          title: 'Errore',
          message: error,
          actionButtonText: 'OK',
          newRoute: ''
        };
        const modalDialog = this.matDialog.open(ModalComponent, dialogConfig);
      }

}
