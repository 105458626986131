<div id="modal-content-wrapper">
  <header id="modal-header">
    <button class="close" mat-button (click)="closeModal()">X</button>
    <h4 class="numberstitle" id="modal-title">{{ modalResetPasswordData.title }} 
      <br/> {{ modalResetPasswordData.present }}</h4>
  </header>
  <div class="filocolorato"></div>
  <section id="modal-body">
    <div class="text-block text-blu text-center">
      {{ modalResetPasswordData.message }}
    </div>
  </section>
  <footer id="modal-footer">
    <a (click)="actionFunction()">
      {{ modalResetPasswordData.actionButtonText }}
    </a>
    
    
  </footer>
</div>
