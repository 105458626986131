import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../app/core/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ModalResetPasswordActionsService {

  constructor(private router: Router,
              private authService: AuthService) { }

  modalAction(modalData: any) {
    switch (modalData.action) {
      case 'goToNewRoute':
        this.goToNewRoute(modalData);
        break;
        case 'loginFB':
          this.loginFB();
          break;
      default:
        break;
    }
  }

  private goToNewRoute(modalData: any) {
    this.router.navigateByUrl(modalData.newRoute);
  }

  private loginFB() {
    // console.log('sono qui');
    this.authService.loginFacebook();
  }


}
